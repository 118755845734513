import { useContext } from 'react';
import AppContext from '../../../store/app-context';

import { FaMap, FaPhone } from 'react-icons/fa';
import './Address.scss';

type addressProps = {
    data: any
};

const Address = (props: addressProps) => {
    const appCtx = useContext(AppContext);
    const address = props.data;

    return (
        <section className="address">
            {address.university && <div>{address.university}</div>}
            <div>{address.streetNumber ? address.streetNumber + ', ' : ''}{address.address1}</div>
            {address.address2 && <div>{address.address2}</div>}
            {address.city && <div>{address.city}</div>}
            <div>
                {address.county && <span>{address.county}, </span>}
                <span>{address.postcode}</span>
            </div>
            <div className="map-link">
                <a href={'https://www.google.co.uk/maps/dir/' + appCtx.geolocation.latitude + ',' + appCtx.geolocation.longitude + '/' + address.latitude + ',' + address.longitude} target="_blank" rel="noreferrer">
                    <FaMap />
                    Get directions
                </a>
            </div>
            <div className="phone">
                <a href={'tel:' + address.phone}>
                    <FaPhone />
                    {address.phone}
                </a>
            </div>
        </section>
    );
};

export default Address;