import { useContext } from "react";
import { useNavigate } from "react-router-dom";

//import { DateTime } from 'luxon';
import AppContext from "../../store/app-context";

import { FaMapMarker } from "react-icons/fa";

type orderCardProps = {
    data: any
};

const OrderCard = (props: orderCardProps) => {
    const appCtx = useContext(AppContext);
    const order = props.data;

    const navigate = useNavigate();

    const openOrder = (event:any) => {
        appCtx.viewOrder(order);

        let phone = order.collection ? order.collection.phone : order.delivery.phone;
        
        appCtx.updateFooter({
            heading: '',
            pathname: '',
            buttons: [
                {
                    label: 'Call customer',
                    action: () => {
                        console.log('Call customer');
                        window.open('tel:' + phone);
                    }
                }
            ]
        });

        navigate(event.target.id);
    };

    const cardClass = () => {
        let classString = '';

        if(order.signature) classString += ' ok';
        if(!order.signature && order.progress > 0) classString += ' warn';

        return classString;
    };

    return (
        <div onClick={openOrder} id={'/courier/order/' + order.id} className={'card order-card' + cardClass()}>
            <div>
                <span className="number">{order.index+1}</span>
                <span>Order #{order.id}</span>
                <span className="stop-type">{order.type}</span>
            </div>
            <div className="bottom">
                <span>Boxes: <strong>{order.progress} of {order.boxes.length}</strong></span>
                <span className="postcode">
                    <FaMapMarker />
                    {order.collection && order.collection.address.postcode}
                    {order.delivery && order.delivery.address.postcode}
                </span>
            </div>
        </div>
    );
};

export default OrderCard;