import { useState, useContext, useEffect, useCallback } from "react";
import AppContext from "../../store/app-context";

import useMock from "../../hooks/use-mock";

import FormControl from "../../components/Form/FormControl";

import './Settings.scss';
import { FaEdit, FaTimes } from 'react-icons/fa';

const Settings = () => {
    const appCtx = useContext(AppContext);

    const [editing, setEditing] = useState(null);
    const [cameraList, setCameraList] = useState([]);

    const { setupLocalData: clearTrainingData } = useMock(null);

    const settings:any = appCtx.settings;

    const changeEditing = (event:any) => {
        console.log({event});
        const setting = event.target.id;
        setEditing(setting);
    };

    const cancelEditing = (event:any) => {
        setEditing(null);
    };

    const settingsForm = [
        {
            label: 'Environment',
            setting: 'environment',
            type: 'select',
            data: [
                { value: 'live', label: 'Live'}, 
                { value: 'test', label: 'Test'}, 
                { value: 'training', label: 'Training'}
            ],
            update: (event:any) => {
                appCtx.updateSetting('environment', event.target.value);
                cancelEditing(null);
            }
        },
        {
            label: 'Camera',
            setting: 'camera',
            type: 'select',
            data: cameraList,
            update: (event:any) => {
                appCtx.updateSetting('camera', event.target.value);
                cancelEditing(null);
            }
        },
        {
            label: 'Training data',
            setting: 'training-data',
            type: 'button',
            data: null,
            update: (event:any) => {
                appCtx.newDialog({
                    text: 'You are about to clear any progress you have made in training mode.',
                    confirm: () => {
                        clearTrainingData({
                            action: 'reset',
                            data: null,
                            endpoint: null
                        });
                    }
                });
            }
        }
    ];

    const getCameraList = useCallback(() => {
        if (!navigator.mediaDevices?.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
        } else {
            // List cameras and microphones.
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                let cameras:any = [];
                devices.forEach((device) => {
                    if(device.kind === 'videoinput'){
                        cameras.push({
                            value: device.deviceId,
                            label: device.label
                        });
                    }
                });

                console.log({cameras});

                if(cameras.length === 1) appCtx.updateSetting('camera', cameras[0].value);

                setCameraList(cameras);
            }).catch((error) => {
                console.error({error});
            });
        }
    }, [appCtx]);

    const formControls = settingsForm.map((control:any) => {
        let niceValue = '';
        if(control.data){
            control.data.forEach((option:any) => {
                if(option.value === settings[control.setting]) niceValue = option.label;
            });
        }

        return (
            <div key={control.setting} className="setting">
                <strong>{control.label}</strong>
                {control.type === 'select' && <div>
                    {editing !== control.setting && <span id={control.setting} className="link" onClick={changeEditing}>
                        <FaEdit className="" />
                        <span>{niceValue}</span>
                    </span>}
                    {editing === control.setting && <div className="form-control">
                        <FormControl config={{
                            type: control.type,
                            value: settings[control.setting],
                            change: control.update,
                            data: control.data
                        }} />
                        <span>
                            <FaTimes className="link danger" onClick={cancelEditing} />
                        </span>
                    </div>}
                </div>}
                {control.type === 'button' && <div>
                    <button type="button" className="small" onClick={control.update}>reset</button>
                </div>}
            </div>
        );
    });

    useEffect(() => {
        getCameraList();
    }, [getCameraList]);

    return(
        <form id="settings">
            {formControls}
        </form>
    );
};

export default Settings;