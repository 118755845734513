import { useContext } from 'react';

import './Message.scss';
import { FaCheckCircle, FaExclamationCircle, FaTimesCircle } from 'react-icons/fa';

import AppContext from "../../../store/app-context";

function Message() {
    const appCtx = useContext(AppContext);

    const typeClasses = [
        'error', 'ok', 'warn'
    ];

    const closeMessage = () => {
        appCtx.newMessage({text: '', type: null});
    };

    const messageType = () => {
        if(appCtx.message.type !== null){
            return typeClasses[appCtx.message.type];
        }else{
            return '';
        }
    };

    return (
        <div id="message" className={appCtx.message.text.length === 0 ? 'hide' : ''} >
            <div className={'inner ' + messageType()} onClick={closeMessage} >
                <section className="modal">
                    <span>
                        {appCtx.message.text}
                    </span>
                    {appCtx.message.type === 1 && <FaCheckCircle className=""/>}
                    {appCtx.message.type === 2 && <FaExclamationCircle className=""/>}
                    {appCtx.message.type === 0 && <FaTimesCircle className=""/>}
                </section>
            </div>
        </div>
    );
}

export default Message;