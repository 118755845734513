import { NavLink } from "react-router-dom";

import './Dashboard.scss';

const Dashboard = () => {
    const data:any = [
        {
            name: 'courier',
            list: [
                {
                    name: 'Collections',
                    progress: 3,
                    total: 5
                },
                {
                    name: 'Deliveries',
                    progress: 2,
                    total: 2
                }
            ]
        },
        {
            name: 'incoming',
            list: [
                {
                    name: 'Received',
                    progress: 19,
                    total: 22
                },
                {
                    name: 'Stored',
                    progress: 6,
                    total: 22
                }
            ]
        },
        {
            name: 'dispatch',
            list: [
                {
                    name: 'Picked',
                    progress: 20,
                    total: 20
                },
                {
                    name: 'Dispatched',
                    progress: 14,
                    total: 20
                }
            ]
        }
    ];

    const numbersColour = (numbers:any) => {
        let classString = '';
        if(numbers.progress === numbers.total) classString += ' ok';
        else if(numbers.progress >= numbers.total/2) classString += ' warn';
        if(numbers.progress < numbers.total/2) classString += ' error';
        return classString;
    };

    return(
        <div id="dashboard">
            {data.map((type:any, index:number) => {
                return(
                    <NavLink className={type.name} to={'/' + type.name} key={index}>
                        <h4>{type.name}</h4>
                        <div>
                            {type.list.map((numbers:any, index:number) => {
                                return (
                                    <div key={index}>
                                        <u>{numbers.name}</u>
                                        <div className={'numbers' + numbersColour(numbers)}>
                                            <span>{numbers.progress}</span>/<span>{numbers.total}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </NavLink>
                );
            })}
        </div>
    );
};

export default Dashboard;