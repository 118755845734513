import { useEffect, useState, useReducer, useContext } from "react";

import AppContext from "../../../store/app-context";
import AuthContext from "../../../store/auth-context";

import './Login.scss';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

type loginProps = {};

const emailReducer = (state: {}, action: any) => {
    if(action.type === 'input'){
        return {
            value: action.value,
            isValid: action.value.length > 0 && action.value.includes('@') && action.value.split('@')[1].length > 0
        }
    }

    return { value: '', isValid: false };
};

const passwordReducer = (state: {}, action: any) => {
    if(action.type === 'input'){
        return {
            value: action.value,
            isValid: action.value.length > 6
        }
    }

    return { value: '', isValid: false };
};

const Login = (props: loginProps) => {
    const appCtx = useContext(AppContext);
    const authCtx = useContext(AuthContext);

    const [validForm, setValidForm] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [emailState, dispatchEmail] = useReducer(emailReducer, {
        value: '',
        isValid: false
    });

    const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
        value: '',
        isValid: false
    });

    const emailChanged = (event: any) => {
        dispatchEmail({
            type: 'input',
            value: event.target.value
        });
    };

    const passwordChanged = (event: any) => {
        dispatchPassword({
            type: 'input',
            value: event.target.value
        });
    };

    const toggleShowPassword = (event: any) => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    useEffect(() => {
        setValidForm(emailState.isValid && passwordState.isValid);
    }, [emailState.isValid, passwordState.isValid]);

    const submitLogin = (event: any) => {
        event.preventDefault();

        authCtx.sendLogIn({
            email: emailState.value,
            password: passwordState.value,
            admin: true
        });
    };

    return (
        <>
            {appCtx.init && <div id="login" className={authCtx.user ? 'hide' : ''}>
                <div className="inner">
                    <img src="/kit-keeper-logo.svg" alt="Kit Keeper logo" />
                    <h1>Logistics App</h1>
                    <h2>Please login to continue</h2>
                    <form autoComplete="off" onSubmit={submitLogin}>
                        <div className="form-control">
                            <label>Email</label>
                            <input type="email" value={emailState.value} onChange={emailChanged} autoComplete="off" />
                        </div>
                        <div className="form-control">
                            <label>
                                Password
                                <span className="show-password link" onClick={toggleShowPassword}>
                                    {showPassword && <FaEyeSlash className="" />}
                                    {!showPassword && <FaEye className="" onClick={toggleShowPassword} />}
                                    {showPassword ? 'hide' : 'show'}
                                </span>
                            </label>
                            <input type={showPassword ? 'text' : 'password'} value={passwordState.value} onChange={passwordChanged} autoComplete="off" />
                        </div>
                        <button type="submit" disabled={!validForm}>Login</button>
                    </form>
                    <p><span className="link">Forgot your password?</span></p>
                </div>
            </div>}
        </>
    );
}

export default Login;