import ReactDOM from 'react-dom';
import { PropsWithChildren } from 'react';

import './Overlay.scss';

type backdropProps = {};

const Backdrop = (props: PropsWithChildren<backdropProps>) => {
    return (
        <>
            {props.children}
        </>
    );
};

type overlayProps = {};

const Overlay = (props: PropsWithChildren<overlayProps>) => {
    return (
        <>
            {ReactDOM.createPortal(<Backdrop>{props.children}</Backdrop>, document.getElementById('overlay-root')!)}
        </>
    );
}

export default Overlay;