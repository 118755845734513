import { useState, useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import useAPI from "../../../hooks/use-api";
import useReader from "../../../hooks/use-reader";

import { useDoubleTap } from 'use-double-tap';

import AppContext from "../../../store/app-context";
import AuthContext from "../../../store/auth-context";

import './BoxList.scss';

type boxListProps = {
    boxes: any,
    progress: any,
    update: (box:any) => void
};

const BoxList = (props: boxListProps) => {
    const [location, setLocation] = useState('');
    const authCtx:any = useContext(AuthContext);
    const appCtx:any = useContext(AppContext);

    const currentLocation = useLocation();
    const params:any = useParams();
    
    const { boxMoveComplete } = useAPI(null);
    const { stopQuagga } = useReader(null);
    
    const boxes = props.boxes;

    const finished = () => {
        return props.progress === props.boxes.length;
    };

    const showMoveButton = () => {
        let boxesScanned = 0;
        let boxesLeftToMove = boxes.length;

        boxes.forEach((boxInList:any) => {
            if(boxInList.scanned) boxesScanned++;
            if(boxMoveComplete(boxInList.status)) boxesLeftToMove--;
        });

        let locationNeeded = currentLocation.pathname.indexOf('incoming/store') >= 0 && appCtx.location.length === 0;
        
        return boxesScanned > 0 && boxesLeftToMove > 0 && !locationNeeded;
    };

    const { calcProgress } = useAPI(null);

    const { sendRequest: moveBoxes } = useAPI((data: any) => {
        appCtx.updateLocation('');

        let warehouse = JSON.parse(JSON.stringify(appCtx.warehouse));
        let order = JSON.parse(JSON.stringify(appCtx.order));

        let newStatus:string = '';

        let allProgress:number = 0;
        
        if(currentLocation.pathname.indexOf('courier/order') >= 0){
            const isCollection = order.type.indexOf('collection') >= 0;
            newStatus = isCollection ? 'Collected by Courier' : 'Returned to Customer';
            order.progress = calcProgress('order', order);
            allProgress = order.progress;
        }
        
        if(currentLocation.pathname.indexOf('courier/warehouse') >= 0){
            newStatus = params.warehouse === 'load' ? 'Out for Delivery' : 'Received by KK';
            warehouse[params.warehouse].progress = calcProgress('warehouse-' + params.warehouse, warehouse[params.warehouse]);
            allProgress = warehouse[params.warehouse].progress;
        }
        
        if(currentLocation.pathname.indexOf('dispatch/pick') >= 0){
            newStatus = 'Ready for Dispatch';
            warehouse.pick.forEach((courier:any) => {
                courier.progress = calcProgress('warehouse-pick', courier);
                allProgress += courier.progress;
            });
        }
        
        if(currentLocation.pathname.indexOf('dispatch/load') >= 0){
            newStatus = 'Out for Delivery';
            warehouse.dispatch.forEach((courier:any) => {
                courier.progress = calcProgress('warehouse-load', courier);
                allProgress += courier.progress;
            });
        }

        if(currentLocation.pathname.indexOf('incoming/receive') >= 0){
            newStatus = 'Received by KK';
            warehouse.receive.forEach((courier:any) => {
                courier.progress = calcProgress('warehouse-unload', courier);
                allProgress += courier.progress;
            });
        }
        
        if(currentLocation.pathname.indexOf('incoming/store') >= 0){
            newStatus = 'In KK Storage';
            warehouse.store.progress = calcProgress('warehouse-store', warehouse.store);
            allProgress = warehouse.store.progress;
        }

        
        data.body.boxIds.forEach((boxId:any) => {
            boxes.forEach((boxInList:any) => {
                if(boxInList.id === boxId){
                    boxInList.status = newStatus;
                    delete boxInList.scanned;
                }
            });
        });
        
        appCtx.updateWarehouse(warehouse);
        appCtx.viewOrder(order);
        
        const endScanning = allProgress === boxes.length;
        if(endScanning) stopQuagga();
    });

    const submitMoveBoxes = () => {
        let data:any = {
            location: '',
            boxIds: []
        };

        data.location = appCtx.location;

        if(currentLocation.pathname.indexOf('courier/order') >= 0) data.location = appCtx.order.type.indexOf('collection') >= 0 ? 'Courier-Incoming' : 'Customer';
        if(currentLocation.pathname.indexOf('courier/warehouse') >= 0) data.location = params.warehouse === 'load' ? 'Courier-Outgoing' : 'WH1-IN';
        if(currentLocation.pathname.indexOf('incoming/receive') >= 0) data.location = 'WH1-IN';
        if(currentLocation.pathname.indexOf('dispatch/pick') >= 0) data.location = 'WH1-OUT';
        if(currentLocation.pathname.indexOf('dispatch/load') >= 0) data.location = 'Courier-Outgoing';

        boxes.forEach((boxInList:any) => {
            if(boxInList.scanned) data.boxIds.push(boxInList.id);
        });

        console.log({data});

        moveBoxes({
            endpoint: 'boxes/move',
            method: 'PUT',
            loading: 'Moving boxes...',
            body: data
        });
    };
    
    const bind = useDoubleTap((event:any) => {
        const thingScanned = event.target.id;
        if(authCtx.user.role === 'ADMIN'){
            if(thingScanned === 'scannedLocation'){
                let newLocation:string = appCtx.location === '' ? 'WH1-St-A-03-2-D' : '';
                appCtx.updateLocation(newLocation);
                props.update(newLocation);
            }else{
                boxes.forEach((boxInList:any) => {
                    if(boxInList.barcode === thingScanned){
                        if(!boxInList.scanned) boxInList.scanned = true;
                        else delete boxInList.scanned;
                        props.update(boxInList);
                    }
                });
            }
        }
    });
    
    useEffect(() => {
        setLocation((prevLocation:any) => {
            let locationString:string = '';
    
            if(currentLocation.pathname.indexOf('courier/order') >= 0 && appCtx.order){
                if(appCtx.order.type.indexOf('delivery') >= 0) locationString = 'the customer';
                if(appCtx.order.type.indexOf('collection') >= 0) locationString = 'your vehicle';
            }
    
            if(currentLocation.pathname.indexOf('courier/warehouse/load') >= 0) locationString = 'your vehicle';
    
            if(currentLocation.pathname.indexOf('incoming/receive') >= 0) locationString = 'warehouse';
            if(currentLocation.pathname.indexOf('incoming/store') >= 0) locationString = 'shelf locations:';

            if(currentLocation.pathname.indexOf('dispatch/pick') >= 0) locationString = 'your dispatch area';
            if(currentLocation.pathname.indexOf('dispatch/load') >= 0) locationString = 'courier vehicle';
    
            return locationString;
        });
    }, [appCtx, currentLocation]);

    return (
        <>
            {showMoveButton() && <button className="move-button" onClick={submitMoveBoxes}>Move</button>}
            {!finished() && <div className="action-message">
                To <strong>{location}</strong>
            </div>}
            {currentLocation.pathname.indexOf('incoming/store') >= 0 && <section id="scannedLocation" className={'location' + (appCtx.location.length > 0 ? ' scanned' : '')} {...bind}>
                {appCtx.location.length > 0 ? appCtx.location : 'Scan a location'}
            </section>}
            <section className="box-list">
                {boxes.map((box:any) => {
                    return (
                        <div key={box.barcode} id={box.barcode} className={'box' + (box.scanned ? ' scanned' : '') + (boxMoveComplete(box.status) ? ' moved' : '')} {...bind}>
                            <div className="barcode">
                                {box.barcode.slice(0,7)}
                                <strong>{box.barcode.slice(7,12)}</strong>
                            </div>
                            {currentLocation.pathname.indexOf('dispatch/pick') >= 0 && <div className="current-location">{box.location}</div>}
                        </div>
                    );
                })}
            </section>
        </>
    );
};

export default BoxList;