import { useContext, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import AppContext from '../../store/app-context';

import './Footer.scss';

const Footer = () => {
    const appCtx = useContext(AppContext);

    let location = useLocation();
  
    useEffect(() => {
        if(appCtx.footer.buttons.length > 0){
            if(appCtx.footer.pathname.length === 0){
                let footer = appCtx.footer;
                footer.pathname = location.pathname;
                appCtx.updateFooter(footer);
            }else{
                if(appCtx.footer.pathname !== location.pathname){
                    appCtx.updateFooter({
                        heading: '',
                        pathname: '',
                        buttons: []
                    });
                }
            }
        }
    }, [location, appCtx]);

    return (
        <>
            {appCtx.footer.buttons.length > 0 && <footer>
                <div className="inner">
                    {appCtx.footer.heading.length > 0 && <h4>{appCtx.footer.heading}</h4>}
                    <div className={'button-container' + (appCtx.footer.buttons.length > 1 ? ' double' : '')}>
                        {appCtx.footer.buttons.map((button:any, index: number) => {
                            return(
                                <button key={index} onClick={button.action}>{button.label}</button>
                            );
                        })}
                    </div>
                </div>
            </footer>}
        </>
    );
}

export default Footer;