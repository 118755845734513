import React, { PropsWithChildren, useState, useEffect, useCallback } from 'react';

const AppContext = React.createContext({
    init: false,
    finishInit: () => {},
    hideMenu: true,
    toggleMenu: (event:any) => {},
    geolocation: {latitude: '', longitude: ''},
    updateGeolocation: (coords:any) => {},
    loading: '',
    newLoading: (text:any) => {},
    message: { text: '', type: null },
    newMessage: (config:any) => {},
    dialog: { text: '', confirm: (data:any) => {} },
    newDialog: (config:any) => {},
    settings: { environment: 'training', camera: '' },
    updateSetting: (name:string, value:any) => {},
    footer: { heading: '', pathname: '', buttons: [] },
    updateFooter: (buttons:any) => {},
    location: '',
    updateLocation: (location:string) => {},
    order: null,
    viewOrder: (data:any) => {},
    warehouse: { load: {}, unload: {}, receive: [], store: { boxes: [] }, pick: [], dispatch: [] },
    updateWarehouse: (data:any) => {}
});

type appProps = {};

export const AppContextProvider = (props: PropsWithChildren<appProps>) => {
    const [init, setInit] = useState(false);
    const [hideMenu, setHideMenu] = useState(true);
    const [geolocation, setGeolocation] = useState({latitude: '', longitude: ''});
    const [loading, setLoading] = useState('');
    const [message, setMessage] = useState({text: '', type: null});
    const [dialog, setDialog] = useState({text: '', confirm: () => {}});
    const [settings, setSettings] = useState({ environment: 'training', camera: '' });
    const [footer, setFooter] = useState({ heading: '', pathname: '', buttons: [] });
    const [location, setLocation] = useState('');
    const [order, setOrder] = useState(null);
    const [warehouse, setWarehouse] = useState({ load: {}, unload: {}, receive: [], store: { boxes: [] }, pick: [], dispatch: [] });

    const finishInit = () => {
        setInit(true);
    };

    const toggleMenu = () => {
        setHideMenu(prevHideMenu => !prevHideMenu);
    };

    const newLoading = (data:any) => {
        setLoading(data);
    };

    const newMessage = (data:any) => {
        setMessage(data);
    };

    const newDialog = (data:any) => {
        setDialog(data);
    };

    const updateGeolocation = (coords:any) => {
        let latitude:any = coords && coords.latitude ? coords.latitude : '';
        let longitude:any = coords && coords.longitude ? coords.longitude : '';
        setGeolocation({latitude: latitude, longitude: longitude});
    };

    const updateSetting = (name:string, value:any) => {
        setSettings((prevSettings:any) => {
            let newSettings = prevSettings;
            newSettings[name] = value;
            localStorage.setItem('deviceSettings', JSON.stringify(newSettings));
            return newSettings;
        });
    };

    const updateFooter = useCallback((config:any) => {
        setFooter(config);
    }, [setFooter]);

    const updateLocation = (locationString:string) => {
        setLocation(locationString);
    };

    const viewOrder = (data:any) => {
        setOrder(data);
    };

    const updateWarehouse = (data:any) => {
        setWarehouse(data);
    };
    
    useEffect(() => {
        let savedSettings:any = localStorage.getItem('deviceSettings');

        const settingsTemplate:any = {
            environment: 'training'
        };

        if(savedSettings){
            savedSettings = JSON.parse(savedSettings);

            Object.keys(settingsTemplate).forEach((setting) => {
                if(!savedSettings[setting]) savedSettings[setting] = settingsTemplate[setting];
            });
        }else{
            savedSettings = settingsTemplate
        }
        
        setSettings(savedSettings);
        localStorage.setItem('deviceSettings', JSON.stringify(savedSettings));
    }, []);

    const providerConfig = {
        init: init,
        finishInit: finishInit,
        hideMenu: hideMenu,
        toggleMenu: toggleMenu,
        geolocation: geolocation,
        updateGeolocation: updateGeolocation,
        loading: loading,
        newLoading: newLoading,
        message: message,
        newMessage: newMessage,
        dialog: dialog,
        newDialog: newDialog,
        settings: settings,
        updateSetting: updateSetting,
        footer: footer,
        updateFooter: updateFooter,
        location: location,
        updateLocation: updateLocation,
        order: order,
        viewOrder: viewOrder,
        warehouse: warehouse,
        updateWarehouse: updateWarehouse
    };

    return (
        <AppContext.Provider value={providerConfig}>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContext;