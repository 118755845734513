import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { IconContext } from "react-icons";

import './App.scss';

import Dashboard from "../pages/Dashboard/Dashboard";

import Courier from "../pages/Courier/Courier";
import CourierOrder from '../pages/Courier/CourierOrder';
import CourierWarehouse from '../pages/Courier/CourierWarehouse';

import Incoming from "../pages/Incoming/Incoming";
import IncomingReceive from '../pages/Incoming/IncomingReceive';
import IncomingStore from '../pages/Incoming/IncomingStore';

import Dispatch from "../pages/Dispatch/Dispatch";
import DispatchPick from '../pages/Dispatch/DispatchPick';
import DispatchLoad from '../pages/Dispatch/DispatchLoad';

import Settings from "../pages/Settings/Settings";
import Tools from "../pages/Tools/Tools";

import Layout, { appLoader } from "../pages/Layout";

import ErrorPage from '../pages/ErrorPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    loader: appLoader,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <Dashboard />},
      { path: '/courier', element: <Courier /> },
      { path: '/courier/order/:order', element: <CourierOrder /> },
      { path: '/courier/warehouse/:warehouse', element: <CourierWarehouse /> },
      { path: '/incoming', element: <Incoming /> },
      { path: '/incoming/receive', element: <IncomingReceive /> },
      { path: '/incoming/store', element: <IncomingStore /> },
      { path: '/dispatch', element: <Dispatch /> },
      { path: '/dispatch/pick', element: <DispatchPick /> },
      { path: '/dispatch/load', element: <DispatchLoad /> },
      { path: '/settings', element: <Settings /> },
      { path: '/tools', element: <Tools /> }
    ]
  },
]);


const App = () => {
  return (
    <IconContext.Provider value={{ className: 'icon' }}>
      <RouterProvider router={router}></RouterProvider>
    </IconContext.Provider>
  );
}

export default App;
