import { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';

import useAPI from '../../hooks/use-api';

import AppContext from '../../store/app-context';

import OrderCard from '../../components/Card/OrderCard';
//import WarehouseCard from '../../components/Card/WarehouseCard';

import './Courier.scss';

const Courier = () => {
    const [loaded, setLoaded] = useState(false);
    const [mapLink, setMapLink] = useState('');
    const [tabShown, setTabShown] = useState('list');

    const [routeData, setRouteData] = useState({
        stops: [],
        load: [],
        unload: []
    });

    const appCtx = useContext(AppContext);

    const navigate = useNavigate();

    const { calcProgress } = useAPI(null);

    const { sendRequest: loadRoute } = useAPI((data: any) => {
        let dataForRoute:any = {
            stops: [],
            load: {
                boxes: [],
                progress: 0
            },
            unload: {
                boxes: [],
                progress: 0
            }
        };

        if(appCtx.settings.environment === 'training'){
            let date = DateTime.now().startOf('day').toISO();
            
            data.orders.forEach((order:any, index:number) => {
                if(order.collection){
                    order.collection.dateTime = DateTime.fromISO(date).set({hour: 0, minute: 0}).toISO();;
                }
                
                if(order.delivery){
                    order.delivery.dateTime = DateTime.fromISO(date).set({hour: 0, minute: 0}).toISO();
                }
            });
        }
        
        data.orders.forEach((order:any, index:number) => {
            let orderCopy = JSON.parse(JSON.stringify(order));
            if(order.collection && order.delivery){
                order.type = 'shipping collection';
                order.delivery = null;
                
                orderCopy.id = orderCopy.id.slice(0, -1) + 'B';
                orderCopy.collection = null;
                orderCopy.type = 'shipping delivery';
            }else{
                if(order.collection) order.type = 'collection';
                if(order.delivery) order.type = 'delivery';
                orderCopy = null
            }
            
            order.progress = calcProgress('order', order);
            dataForRoute.stops.push(order);
            
            if(orderCopy){
                orderCopy.progress = calcProgress('order', orderCopy);
                dataForRoute.stops.push(orderCopy);
            }

            if(order.type === 'collection') dataForRoute.unload.boxes = dataForRoute.unload.boxes.concat(order.boxes);
            if(order.type === 'delivery') dataForRoute.load.boxes = dataForRoute.load.boxes.concat(order.boxes);

            dataForRoute.unload.progress = calcProgress('warehouse-unload', dataForRoute.unload);
            dataForRoute.load.progress = calcProgress('warehouse-load', dataForRoute.load);
        });

        dataForRoute.stops.forEach((stop:any) => {
            let link = 'https://www.google.com/maps/embed?pb=!1m34!1m12!1m3!1d134744.01055825007!2d-0.1009970808844502!3d51.13194114316884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m19!3e0!4m5!1s0x4875ebbacbeb9f45%3A0x93656b9cc5ad7469!2s14%20Orchard%20Road%2C%20Horsham%20RH13%205NF%2C%20UK!3m2!1d51.0643494!2d-0.3109402!4m3!3m2!1d51.102410199999994!2d-0.2364395!4m3!3m2!1d51.1737426!2d-0.1722047!4m3!3m2!1d51.271397!2d-0.32086169999999997!5e0!3m2!1sen!2suk!4v1679432872148!5m2!1sen!2suk';
            setMapLink(link);
        });

        /* dataForRoute.stops.sort((a:any, b:any) => {
            if(a.time < b.time) return -1;
            else return 1;
        }); */

        console.log('processed data', dataForRoute);
        
        setRouteData(dataForRoute);

        appCtx.updateWarehouse({
            unload: dataForRoute.unload,
            load: dataForRoute.load,
            receive: [],
            store: []
        });

        appCtx.updateFooter({
            heading: 'Warehouse actions',
            pathname: '',
            buttons: [
                /* {
                    label: 'Unload',
                    action: () => {
                        navigate('/courier/warehouse/unload');
                    }
                }, */
                {
                    label: 'Load',
                    action: () => {
                        navigate('/courier/warehouse/load');
                    }
                }
            ]
        });
    });
    
    useEffect(() => {
        if(!loaded){
            loadRoute({
                endpoint: 'courier',
                method: 'GET',
                loading: 'Loading your route...'
            });

            setLoaded(true);
        }
    }, [loadRoute, setLoaded, loaded]);

    const tabChange = (event:any) => {
        const tab = event.target.id;
        setTabShown(tab);
    };

    return(
        <>
            <ul className="tabs">
                <li id="list" className={tabShown === 'list' ? 'active' : ''} onClick={tabChange}>List</li>
                <li id="map" className={tabShown === 'map' ? 'active' : ''} onClick={tabChange}>Map</li>
            </ul>
            {tabShown === 'list' &&
                <section>
                    {routeData.stops.map((stop:any, index: number) => {
                        stop.index = index;
                        return(<OrderCard key={index} data={stop} />);
                    })}
                </section>
            }
            {tabShown === 'map' &&
                <iframe title="courier-route-map" src={mapLink} width="600" height="450" style={{border: '0'}} loading="lazy"></iframe>
            }
        </>
    );
};

export default Courier;