import { useEffect, useState, useCallback, useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import SignaturePad from 'signature_pad';

import './Signature.scss';

function Signature() {
    const signaturePad:any = useRef();
    
    const [validSig, setValidSig] = useState(false);
    
    const initSignature = useCallback((canvas: any) => {
        const resizeCanvas = (canvas: any) => {
            const ratio =  Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            canvas.getContext("2d").scale(ratio, ratio);
            signaturePad.current.clear(); // otherwise isEmpty() might return incorrect value
        };
  
        const checkValid = () => {
            let valid:any = signaturePad.current.isEmpty();
            console.log('checkValid', valid);
            setValidSig(valid !== undefined ? !valid : false);
        };

        let newSig = new SignaturePad(canvas);
        signaturePad.current = newSig;
        signaturePad.current.addEventListener('endStroke', checkValid);
        
        window.addEventListener('resize', () => { resizeCanvas(canvas); });
        resizeCanvas(canvas);
    }, []);
    
    useEffect(() => {
        let canvasCheck = setInterval(function(){
            let canvas:any = document.getElementById('signature-canvas');

            if(canvas){
                initSignature(canvas);
                clearInterval(canvasCheck);
            }
        }, 100);
    }, [initSignature]);

    const clearCanvas = () => {
        signaturePad.current.clear();
        setValidSig(false);
    };

    return (
        <div id="signature-container">
            <canvas id="signature-canvas">
                Signature
            </canvas>
            <hr className="dashed" />
            {validSig && <button className="danger small" onClick={clearCanvas}><FaTimes></FaTimes></button>}
        </div>
    );
}

export default Signature;