import Layout from "./Layout"

const ErrorPage = () => {
 return (
    <Layout>
        <p>Page not found</p>
    </Layout>
 );
};

export default ErrorPage;