import { useContext } from 'react';

import './Loading.scss';

import AppContext from "../../../store/app-context";

const Loading = () => {
    const appCtx = useContext(AppContext);

    return (
        <div id="loading" className={appCtx.loading.length === 0 ? 'hide' : ''} >
            <div className="inner">
                {appCtx.loading}
            </div>
        </div>
    );
};

export default Loading;