import { useContext } from 'react';

import AppContext from "../../../store/app-context";

import './Dialog.scss';

const Dialog = () => {
    const appCtx = useContext(AppContext);

    const confirmAction = () => {
        appCtx.dialog.confirm(null);
        cancelAction();
    };

    const cancelAction = () => {
        appCtx.newDialog({ text: '', confirm: () => {} });
    };

    return (
        <div id="dialog" className={appCtx.dialog.text.length === 0 ? 'hide' : ''}>
            <div className="inner">
                <div className="modal">
                    <p>{appCtx.dialog.text}</p>
                    <p><strong>Are you sure?</strong></p>
                    <p>
                        <button className="small danger" onClick={cancelAction}>No, cancel</button>
                        <button className="small confirm" onClick={confirmAction}>Yes</button>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Dialog;