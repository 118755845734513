import { useContext, useCallback } from "react";
import AppContext from '../store/app-context';

const useMock = (callback: any) => {
    const appCtx = useContext(AppContext);

    const responseTime = 1000;

    const getFile = useCallback(async (config: any) => {
        const JSONfiles:any = {
            'courier': 'courier.json',
            'incoming': 'incoming.json',
            'dispatch': 'dispatch.json'
        };

        try {
            const url = '/data/' + JSONfiles[config.endpoint];
            
            const response = await fetch(url);
            const data = await response.json();
            
            setTimeout(() => {
                appCtx.newLoading('');
                
                if(response.ok){
                    console.log(url, data);
    
                    setupLocalData({
                        action: 'setup',
                        data: data,
                        endpoint: config.endpoint
                    });
    
                    if(callback) callback(data);
                }else{
                    appCtx.newLoading('');
    
                    throw new Error(data.error, {
                        cause: response.status,
                    });
                }
            }, responseTime);
        } catch (error:any) {
            setTimeout(() => {
                console.log(error);
                appCtx.newLoading('');
                appCtx.newMessage({
                    text: error.message,
                    type: 0
                });
            }, responseTime);
        }
        
    }, [appCtx, callback]);

    const updateData = useCallback(async (config: any) => {
        console.log('updateData', config);
        let localData:any = localStorage.getItem('training');

        if(localData){
            localData = JSON.parse(localData);

            if(config.endpoint === 'boxes/move'){
                let status:string = '';

                let location:string = JSON.parse(JSON.stringify(config.body.location));

                if(location.indexOf('-St-') >= 0) location = location.split('-St-')[0] + '-St';

                switch(location){
                    case 'Customer':
                        status = 'Returned to Customer'
                        break;
                    case 'Courier-Incoming':
                        status = 'Collected by Courier';
                        break;
                    case 'WH1-IN':
                        status = 'Received by KK';
                        break;
                    case 'WH1-St':
                        status = 'In KK Storage';
                        break;
                    case 'WH1-OUT':
                        status = 'Ready for Dispatch';
                        break;
                    case 'Courier-Outgoing':
                        status = 'Out for Delivery';
                        break;
                }

                config.body.boxIds.forEach((boxId:any) => {
                    localData.boxes[boxId] = {
                        location: config.body.location,
                        status: status
                    };
                });
            }

            if(config.endpoint === 'courier/stop-signature'){
                localData.orders[config.body.orderId].signature = true;
            }
        }

        localStorage.setItem('training', JSON.stringify(localData));
        
        setTimeout(() => {
            appCtx.newLoading('');
        }, responseTime);
        
        if(callback) callback(config);
    }, [appCtx, callback]);

    const setupLocalData = (config:any) => {
        let localData:any = localStorage.getItem('training');

        if(!localData || config.action === 'reset'){
            localData = {
                boxes: {},
                orders: {}
            };
        }else{
            localData = JSON.parse(localData);
        }

        if(config.action === 'setup'){
            if(config.endpoint === 'courier'){
                config.data.orders.forEach((order:any) => {
                    order.boxes.forEach((box:any) => {
                        if(!localData.boxes[box.id]){
                            localData.boxes[box.id] = {
                                location: box.location,
                                status: box.status
                            };
                        }
                    });

                    if(order.collection && order.delivery){
                        let orderCopy = order.id + 'B';
                        order.id = order.id + 'A';

                        if(!localData.orders[order.id]) localData.orders[order.id] = { signature: false };
                        if(!localData.orders[orderCopy]) localData.orders[orderCopy] = { signature: false };
                    }else{
                        if(!localData.orders[order.id]) localData.orders[order.id] = { signature: false };
                    }

                });
            }

            if(config.endpoint === 'incoming'){
                config.data.couriers.forEach((courier:any) => {
                    courier.orders.forEach((order:any) => {
                        order.boxes.forEach((box:any) => {
                            if(!localData.boxes[box.id]){
                                localData.boxes[box.id] = {
                                    location: box.location,
                                    status: box.status
                                };
                            }
                        });
                        if(!localData.orders[order.id]) localData.orders[order.id] = { signature: false };
                    });
                });
            }
        }

        localStorage.setItem('training', JSON.stringify(localData));
    };

    return {
        getFile,
        updateData,
        setupLocalData
    };
};

export default useMock;