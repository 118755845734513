import './Accordion.scss';
import { FaChevronCircleUp } from 'react-icons/fa';

type accordionProps = {
    config: any,
    shown: string,
    toggle: (event:any) => void
};

const Accordion = (props: accordionProps) => {
    const config = props.config;

    return (
        <section className="accordion">
            <h3 onClick={props.toggle} id={config.name}>
                <span>{config.heading}</span>
                {config.headingExtra && <span className="extra">{config.headingExtra}</span>}
                <FaChevronCircleUp className={props.shown === config.name ? 'rotated' : ''} />
            </h3>
            <div className={props.shown === config.name ? 'shown' : ''}>{config.content}</div>
        </section>
    );
};

export default Accordion;