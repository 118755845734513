import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { useDrag } from '@use-gesture/react';

import AppContext from "../../../store/app-context";
import AuthContext from "../../../store/auth-context";

import './Menu.scss';
import { FaAngleDoubleLeft } from 'react-icons/fa';

const Menu = () => {
    const appCtx = useContext(AppContext);
    const authCtx = useContext(AuthContext);

    const user:any = authCtx.user;

    const menuConfig = [
        {
            label: 'Dashboard',
            path: '/',
            roles: ['ADMIN', 'WAREHOUSE', 'COURIER']
        },
        {
            label: 'Courier',
            path: '/courier',
            roles: ['ADMIN', 'COURIER']
        },
        {
            label: 'Incoming',
            path: '/incoming',
            roles: ['ADMIN', 'WAREHOUSE']
        },
        {
            label: 'Dispatch',
            path: '/dispatch',
            roles: ['ADMIN', 'WAREHOUSE']
        },
        /* {
            label: 'Tools',
            path: '/tools',
            roles: ['ADMIN', 'WAREHOUSE', 'COURIER']
        }, */
        {
            label: 'Settings',
            path: '/settings',
            roles: ['ADMIN', 'WAREHOUSE', 'COURIER']
        }
    ];

    const menuElements = menuConfig.map(option => {
        return (user && option.roles.indexOf(user.role) >= 0 && 
            <li key={option.label}>
            <NavLink to={option.path} className={({isActive}) => isActive ? 'active' : ''} onClick={appCtx.toggleMenu}>{ option.label }</NavLink>
        </li>);
    });

    const menuClass = () => {
        return appCtx.hideMenu ? 'hide' : '';
    };

    const bind = useDrag((state) => {
       // if(state.direction[1] === 1 && state.direction[0] === -1) appCtx.toggleMenu(true);
    }, {});

    return (
        <div id="menu" className={menuClass()} {...bind()}>
            <div className="inner">
                <div className="panel">
                    <FaAngleDoubleLeft className="link" onClick={appCtx.toggleMenu} />
                    {user && <div>
                        <p>Hi {user.firstName}!<br />You have the <strong>{user.role}</strong> role.</p>
                        <button className="small" onClick={authCtx.logOut}>Log out</button>
                    </div>}
                    <ul>
                        {menuElements}
                    </ul>
                </div>
                <div className="screen" onClick={appCtx.toggleMenu}></div>
            </div>
        </div>
    );
}

export default Menu;