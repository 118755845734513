import { useState, useContext } from "react";

import AppContext from "../store/app-context";
import ReaderContext from "../store/reader-context";

import Quagga from 'quagga';
import _ from 'underscore';

const useReader = (callback: any) => {
    const appCtx = useContext(AppContext);
    const readerCtx = useContext(ReaderContext);

    const [handler, setHandler] = useState(() => {});

    const startQuagga = (config:any) => {
        console.log('startQuagga');
        
        const quaggaTarget:any = document.querySelector('#quagga');
        
        Quagga.init({
            inputStream : {
                name : "Live",
                type : "LiveStream",
                target: quaggaTarget,
                constraints: {
                    width: 640,
                    height: 480,
                    facingMode: "environment",
                    deviceId: appCtx.settings.camera
                }
            },
            decoder : {
                readers : ["code_128_reader"]
            }
        }, function(err:any) {
            if (err) {
                console.log({err});
                return
            }
            
            Quagga.start();
            readerCtx.updateStarted(true);
            readerCtx.updateConfig(config);

            const processBarcode = (data:any) => {
                let barcode:string = '';

                if (data && data.codeResult && data.codeResult.code)  barcode = data.codeResult.code.replace(/\*/g, '');

                if(barcode.length > 0 ){
                    let regEx = new RegExp(/^\d+$/);
                    let isBox = regEx.test(barcode);
        
                    if(isBox){
                        let boxFound = false;
                        config.boxes.forEach((boxInList:any) => {
                            if(boxInList.barcode === barcode){
                                boxFound = true;
                                if(!boxInList.scanned){
                                    boxInList.scanned = true;
                                    readerCtx.sounds.ok.play();
                                }else{
                                    readerCtx.updateMessages('add', {
                                        type: 'warn',
                                        text: barcode + ' has already been scanned. Please check for duplicates.'
                                    });
                                    readerCtx.sounds.warn.play();
                                }
        
                                config.update(boxInList);
                            }
                        });
        
                        if(!boxFound) {
                            readerCtx.updateMessages('add', {
                                type: 'error',
                                text: barcode + ' is not in this list. Please check you are scanning the correct boxes.'
                            });
                            readerCtx.sounds.error.play();
                        }
                    }else{
                        if(appCtx.location.length === 0){
                            readerCtx.sounds.ok.play();
                            appCtx.updateLocation(barcode);
                            config.update(barcode);
                        }
                    }
                }
            };
            
            const debouncedProcessBarcode = _.debounce(processBarcode, 1000, true);
            setHandler(debouncedProcessBarcode);

            Quagga.onDetected(debouncedProcessBarcode);
        });
    };

    const stopQuagga = () => {
        console.log('stopQuagga');

        Quagga.stop();
        Quagga.offDetected(handler);
        
        const quaggaTarget:any = document.querySelector('#quagga');
        quaggaTarget.innerHTML = '';
        
        readerCtx.updateStarted(false);
        readerCtx.updateConfig({ boxes: [], update: (box:any) => {} });
        readerCtx.updateMessages('clear', []);
    };

    return {
        startQuagga,
        stopQuagga
    };
};

export default useReader;