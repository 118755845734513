import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAPI from '../../hooks/use-api';

import AppContext from '../../store/app-context';

import './Dispatch.scss';
import { FaCheck } from 'react-icons/fa';

const Dispatch = () => {
    const [loaded, setLoaded] = useState(false);

    const [dispatchData, setDispatchData] = useState({
        pick: [],
        load: []
    });

    const appCtx = useContext(AppContext);

    const { calcProgress } = useAPI(null);

    const navigate = useNavigate();

    const { sendRequest: loadDispatch } = useAPI((data: any) => {
        let dataForDispatch:any = {
            pick: [],
            load: []
        };

        if(appCtx.settings.environment === 'training'){
            data.couriers.forEach((courier:any, index:number) => {
                let newCourier:any = courier.courier;
                newCourier.boxes = []
                newCourier.progress = 0;

                console.log({courier});

                courier.orders.forEach((order:any, index:number) => {
                    newCourier.boxes = newCourier.boxes.concat(order.boxes);
                });

                
                newCourier.progress = calcProgress('warehouse-pick', newCourier);
                dataForDispatch.pick.push(newCourier);
                
                newCourier = JSON.parse(JSON.stringify(newCourier));
                newCourier.progress = calcProgress('warehouse-load', newCourier);
                dataForDispatch.load.push(newCourier);
            });
        }

        console.log('processed data', dataForDispatch);
        
        setDispatchData(dataForDispatch);

        appCtx.updateWarehouse({
            dispatch: dataForDispatch.load,
            pick: dataForDispatch.pick
        });

        appCtx.updateFooter({
            heading: '',
            pathname: '',
            buttons: [
                {
                    label: 'Pick',
                    action: () => {
                        navigate('/dispatch/pick');
                    }
                },
                {
                    label: 'Load',
                    action: () => {
                        navigate('/dispatch/load');
                    }
                }
            ]
        });
    });

    useEffect(() => {
        if(!loaded){
            loadDispatch({
                endpoint: 'dispatch',
                method: 'GET',
                loading: 'Loading dispatch boxes...'
            });

            setLoaded(true);
        }
    }, [loadDispatch, setLoaded, loaded]);

    return(
        <div id="dispatch">
            <div>
                <h2>Pick</h2>
                {dispatchData.pick.map((courier:any) => {
                    return (
                        <div key={courier.id} className={'courier' + (courier.progress === courier.boxes.length ? ' ok' : '')}>
                            <div className="underline">{courier.vehicleId}</div>
                            <div>
                                <span>{courier.progress} of {courier.boxes.length}</span>
                                {courier.progress === courier.boxes.length && <FaCheck />}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div>
                <h2>Load</h2>
                {dispatchData.load.map((courier:any) => {
                    return (
                        <div key={courier.id} className={'courier' + (courier.progress === courier.boxes.length ? ' ok' : '')}>
                            <div className="underline">{courier.vehicleId}</div>
                            <div>
                                <span>{courier.progress} of {courier.boxes.length}</span>
                                {courier.progress === courier.boxes.length && <FaCheck />}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Dispatch;