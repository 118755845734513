import './FormControl.scss';

type controlProps = {
    config: {
        type: string,
        value: any,
        change: (event:any) => void,
        data: any
    }
};

const FormControl = (props: controlProps) => {
    return (
        <>
            {props.config.type === 'select' &&
                <select value={props.config.value} onChange={props.config.change}>
                    {props.config.data.map((item:any) => {
                        return <option key={item.value} value={item.value}>{item.label}</option>
                    })}
                </select>
            }
        </>
    );
};

export default FormControl;