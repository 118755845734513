import { useContext, useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";

import AppContext from "../../../store/app-context";

const Geolocation = () => {
    const appCtx = useContext(AppContext);

    const [check, setCheck] = useState(0);

    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });

    useEffect(() => {
        if(!isGeolocationAvailable){
            console.log('Geolocation not supported');
        }else{
            if(!isGeolocationEnabled){
                console.log('Geolocation is not enabled');
            }else{
                if(coords){
                    if(check === 0){
                        appCtx.updateGeolocation(coords);
                        const timestamp = Math.floor(Date.now() / 1000);
                        setCheck(timestamp)
                        setTimeout(() => {
                            setCheck(0);
                        }, 60000);
                    }
                }
            }
        }
    }, [appCtx, coords, isGeolocationAvailable, isGeolocationEnabled, check, setCheck]);

    return(
        <>
            <p style={{display: 'none'}}>{coords?.latitude}, {coords?.longitude}</p>
        </>
    );
};

export default Geolocation;