import { useContext, useCallback } from "react";
import useMock from './use-mock';

import AppContext from '../store/app-context';
import AuthContext from '../store/auth-context';

const useAPI = (callback: any) => {
    const appCtx = useContext(AppContext);
    const authCtx = useContext(AuthContext);

    const { getFile, updateData } = useMock(callback);

    const currentLocation = window.location;
    
    const sendRequest = useCallback(async (config: any) => {
        appCtx.newLoading(config.loading);

        let useAPI = appCtx.settings.environment !== 'training' || config.doNotMock;

        if(useAPI){
            const environments:any = {
                test: 'https://test.kitkeeper.co.uk/api/',
                live: 'https://book.kitkeeper.co.uk/api/'
            };

            const trainingEnvironment = 'test';
            const currentEnvironment = appCtx.settings.environment === 'training' ? trainingEnvironment : appCtx.settings.environment;
            
            const url = environments[currentEnvironment] + config.endpoint;

            let options:any = {
                method: config.method,
                body: JSON.stringify(config.body),
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const user = authCtx.user || {jwt: ''};

            if(user.jwt.length > 0){
                if(config.auth) options.headers['Authorization'] = 'bearer ' + user.jwt;
            }

            //return;

            try {
                const response = await fetch(url, options);
                const data = await response.json();

                appCtx.newLoading('');
                
                if(response.ok){
                    console.log(config.endpoint, response, data);
                    if(callback) callback(data);
                }else{
                    throw new Error(data.error, {
                        cause: response.status,
                    });
                }
            } catch (error:any) {
                console.log(error);
                appCtx.newLoading('');
                appCtx.newMessage({
                    text: error.message,
                    type: 0
                });
            }
        }else{
            switch(config.method){
                case 'GET':
                    getFile(config);
                    break;
                case 'PUT':
                    updateData(config);
                    break;
                case 'POST':
                    updateData(config);
                    break;
            }
        }
    }, [appCtx, authCtx, callback, getFile, updateData]);

    const boxStatus = (statusString:any) => {
        const statuses = [
            'With Customer',        // 0
            'Collected by Courier', // 1
            'Received by KK',       // 2
            'In KK Storage',        // 3
            'Ready for Dispatch',   // 4
            'Out for Delivery',     // 5
            'Returned to Customer'  // 6
        ];

        return statuses.indexOf(statusString);
    };
    
    const boxMoveComplete = (status:string) => {
        let boxMoved = false;
        let order = appCtx.order || {type: ''};

        if(currentLocation.pathname.indexOf('courier/order') >= 0 && appCtx.order){
            if(order.type.indexOf('delivery') >= 0) boxMoved = boxStatus(status) === 6;
            if(order.type === 'collection') boxMoved = boxStatus(status) >= 1 && boxStatus(status) <= 3;
            if(order.type === 'shipping collection') boxMoved = boxStatus(status) === 1 || boxStatus(status) === 6;
        }

        if(currentLocation.pathname.indexOf('courier/warehouse/load') >= 0) boxMoved = boxStatus(status) === 5 || boxStatus(status) === 6;

        if(currentLocation.pathname.indexOf('incoming/receive') >= 0) boxMoved = boxStatus(status) >= 2 && boxStatus(status) <= 3;
        if(currentLocation.pathname.indexOf('incoming/store') >= 0) boxMoved = boxStatus(status) === 3;

        if(currentLocation.pathname.indexOf('dispatch/pick') >= 0) boxMoved = boxStatus(status) === 4 || boxStatus(status) === 5 || boxStatus(status) === 6;
        if(currentLocation.pathname.indexOf('dispatch/load') >= 0) boxMoved = boxStatus(status) === 5 || boxStatus(status) === 6;

        return boxMoved;
    };

    const calcProgress = (type: string, data: any) => {
        let localData:any = localStorage.getItem('training');
        let progress = 0;

        if(localData){
            localData = JSON.parse(localData);

            if(type === 'order'){
                data.signature = localData.orders[data.id].signature;
            }

            data.boxes.forEach((box:any) => {
                if(localData.boxes[box.id]){
                    box.status = localData.boxes[box.id].status;
                    box.location = localData.boxes[box.id].location;
                }
            });
        }

        data.boxes.forEach((box:any) => {
            if(type === 'order'){
                if(data.type === 'collection' && boxStatus(box.status) >= 1) progress++;
                if(data.type === 'delivery' && boxStatus(box.status) === 6) progress++;
                if(data.type === 'shipping collection' && boxStatus(box.status) >= 1) progress++;
                if(data.type === 'shipping delivery' && boxStatus(box.status) === 6) progress++;
            }else{
                if(type === 'warehouse-unload' && (boxStatus(box.status) === 2 || boxStatus(box.status) === 3)) progress++;
                if(type === 'warehouse-store' && boxStatus(box.status) === 3) progress++;
                if(type === 'warehouse-pick' && boxStatus(box.status) >= 4) progress++;
                if(type === 'warehouse-load' && boxStatus(box.status) >= 5) progress++;
            }
        });

        return progress;
    };

    return {
        sendRequest,
        boxStatus,
        boxMoveComplete,
        calcProgress
    };
};

export default useAPI;