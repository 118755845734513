import { useState, useContext, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useAPI from '../../hooks/use-api';
import useReader from '../../hooks/use-reader';

import Accordion from "../../components/Shared/Accordion/Accordion";
import BoxList from "../../components/Shared/BoxList/BoxList";
import Address from "../../components/Shared/Address/Address";
import Signature from "../../components/Shared/Signature/Signature";

//import { DateTime } from 'luxon';

import AppContext from "../../store/app-context";

import './CourierOrder.scss';
import { FaCheck } from "react-icons/fa";

const CourierOrder = () => {
    const appCtx:any = useContext(AppContext);

    const navigate = useNavigate();

    const [section, setSection] = useState('details');

    const { startQuagga } = useReader(null);
    
    const { sendRequest: submitSignature } = useAPI((data: any) => {
        let order = JSON.parse(JSON.stringify(appCtx.order));
        order.signature = true;
        appCtx.viewOrder(order);
    });
    
    const updateBox = useCallback((box:any) => {
        let order = JSON.parse(JSON.stringify(appCtx.order));

        order.boxes.forEach((boxInList:any) => {
            if(boxInList.barcode === box.barcode){
                boxInList = box;
            }
        });

        appCtx.viewOrder(order);
    }, [appCtx]);

    const setupFooter = useCallback((showSection:string) => {
        let footerConfig:any = {
            heading: '',
            pathname: '',
            buttons: []
        };

        const buttons:any = {
            details: {
                label: 'Call customer',
                action: () => {
                    console.log('Call customer');
                    window.open('tel:07700000000', '_self');
                }
            },
            scan: {
                label: 'Scan',
                action: () => {
                    startQuagga({
                        boxes: appCtx.order.boxes,
                        update: updateBox
                    });
                }
            },
            signature: {
                label: 'Submit signature',
                action: () => {
                    let data:any = {
                        location: '',
                        boxIds: []
                    };

                    data.location = appCtx.order.type.indexOf('collection') >= 0 ? 'Courier-Incoming' : 'Customer';

                    appCtx.order.boxes.forEach((box:any) => {
                        if(box.scanned) data.boxIds.push(box.id);
                    });

                    submitSignature({
                        endpoint: 'courier/stop-signature',
                        method: 'POST',
                        loading: 'Submitting signature...',
                        body: {
                            orderId: appCtx.order.id,
                            imageData: null // get from signature pad
                        }
                    });
                }
            }
        };
        
        if(buttons[showSection]) footerConfig.buttons.push(buttons[showSection]);

        appCtx.updateFooter(footerConfig);
    }, [appCtx, startQuagga, submitSignature, updateBox]);

    const sections:any = {
        details: {
            heading: 'Details',
            name: 'details',
            content: ''
        },
        scan: {
            heading: 'Scan boxes',
            headingExtra:  appCtx.order && (appCtx.order.progress === appCtx.order.boxes.length ? <FaCheck className="ok" /> : <span>{appCtx.order.progress + ' of ' + appCtx.order.boxes.length}</span>),
            name: 'scan',
            content: <>
                {appCtx.order && <BoxList boxes={appCtx.order.boxes} progress={appCtx.order.progress} update={updateBox} />}
            </>
        },
        signature: {
            heading: 'Signature',
            headingExtra: appCtx.order && (appCtx.order.signature ? <FaCheck className="ok" /> : <span>not signed yet</span>),
            name: 'signature',
            content: <Signature />
        }
    };

    if(appCtx.order){
        if(appCtx.order.collection) sections.details.content = <Address data={appCtx.order.collection.address} />;
        if(appCtx.order.delivery) sections.details.content = <Address data={appCtx.order.delivery.address} />;
    }

    const toggleSection = useCallback((event:any) => {
        const showSection = event.target.id;
        const alreadyOpen = section === showSection;

        setSection((prevSection:any) => {
            return alreadyOpen ? '' : showSection;
        });
        
        setTimeout(() => {
            setupFooter(alreadyOpen ? '' : showSection);
        }, 300);
    }, [setSection, setupFooter, section]);

    useEffect(() => {
        if(!appCtx.order){
            navigate('/courier');
        }
    }, [appCtx, navigate]);

    return(
        <section>
            {appCtx.order && 
                <>
                    <h1>Order # {appCtx.order.id}</h1>
                    <h2 className="uppercase">{appCtx.order.type}</h2>
                    <Accordion config={sections.details} shown={section} toggle={toggleSection} />
                    <Accordion config={sections.scan} shown={section} toggle={toggleSection} />
                    <Accordion config={sections.signature} shown={section} toggle={toggleSection} />
                </>
            }
        </section>
    );
};

export default CourierOrder;