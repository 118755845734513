import React, { PropsWithChildren, useState, useEffect, useCallback } from 'react';

const ReaderContext = React.createContext({
    started: false,
    updateStarted: (data:any) => {},
    config: { boxes: [], update: (box:any) => {} },
    updateConfig: (config:any) => {},
    messages: [],
    updateMessages: (mode:string, data:{}) => {},
    sounds: {
        ok: new Audio('/ok.mp3'),
        warn: new Audio('/warn.mp3'),
        error: new Audio('/error.mp3')
    },
    updateSounds: (data:{}) => {}
});

type readerProps = {};

export const ReaderContextProvider = (props: PropsWithChildren<readerProps>) => {
    const [started, setStarted] = useState(false);
    const [config, setConfig] = useState({ boxes: [], update: (box:any) => {} });
    const [messages, setMessages] = useState([]);

    const [sounds, setSounds] = useState({
        ok: new Audio('/ok.mp3'),
        warn: new Audio('/warn.mp3'),
        error: new Audio('/error.mp3')
    });

    const updateStarted = (data:any) => {
        setStarted((prevStarted:any) => {
            return data;
        });
    };

    const updateConfig = (newConfig:any) => {
        setConfig((prevConfig:any) => {   
            return newConfig;
        });
    };

    const updateMessages = (mode:string, data:any) => {
        setMessages((prevMessages:any) => {
            let currentMessages = JSON.parse(JSON.stringify(prevMessages));
            if(mode === 'add') currentMessages.unshift(data);
            if(mode === 'clear') currentMessages = [];
            return currentMessages;
        });
    };

    const updateSounds = useCallback(() => {
        setSounds((prevSounds:any) => {
            return {
                ok: new Audio('/ok.mp3'),
                warn: new Audio('/warn.mp3'),
                error: new Audio('/error.mp3')
            };
        });
    }, [setSounds]);

    useEffect(() => {
        updateSounds();
        new AudioContext(); 
    }, [updateSounds]);

    const providerConfig = {
        started: started,
        updateStarted: updateStarted,
        config: config,
        updateConfig: updateConfig,
        messages: messages,
        updateMessages: updateMessages,
        sounds: sounds,
        updateSounds: updateSounds
    };

    return (
        <ReaderContext.Provider value={providerConfig}>
            {props.children}
        </ReaderContext.Provider>
    );
};

export default ReaderContext;