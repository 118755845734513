import React, { PropsWithChildren, useState, useEffect, useContext, useCallback } from 'react';
import useAPI from '../hooks/use-api';

import AppContext from './app-context';

const AuthContext = React.createContext({
    user: null,
    updateUser: (user: {}) => {},
    sendLogIn: (credentials: {}) => {},
    logOut: () => {}
});

type authProps = {};

export const AuthContextProvider = (props: PropsWithChildren<authProps>) => {
    const appCtx = useContext(AppContext);
    const [user, setUser] = useState(null);
    
    const logInCallback = useCallback((data: any) => {
        const userData = data.user;
        userData.jwt = data.jwt;
        localStorage.setItem('user', JSON.stringify(userData));
        updateUser(userData);
        appCtx.newMessage({
            text: 'Welcome, ' + data.user.firstName,
            type: 1
        });
    }, [appCtx]);

    const { sendRequest: logIn } = useAPI(logInCallback);

    const sendLogIn = (credentials: any) => {
        logIn({
            endpoint: 'login',
            method: 'POST',
            body: credentials,
            loading: 'Logging in...',
            doNotMock: true
        });
    };

    const logOut = () => {
        appCtx.newDialog({
            text: 'You are about to log out.',
            confirm: () => {
                localStorage.removeItem('user');
                updateUser(null);
                appCtx.toggleMenu(false);
            }
        });
    };

    const updateUser = (user:any) => {
        setUser(user);
    };

    useEffect(() => {
        const loggedInUser = localStorage.getItem('user');
        if(loggedInUser) setUser(JSON.parse(loggedInUser));
        appCtx.finishInit();
    }, [appCtx]);

    const providerConfig = {
        user: user,
        updateUser: updateUser,
        sendLogIn: sendLogIn,
        logOut: logOut
    };

    return (
        <AuthContext.Provider value={providerConfig}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;