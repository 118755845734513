import { PropsWithChildren, useContext, useEffect } from 'react';

import { useDrag } from '@use-gesture/react';

import useAPI from '../hooks/use-api';

import { Outlet/* , useLoaderData */ } from "react-router-dom";

import AuthContext from "../store/auth-context";
import AppContext from "../store/app-context";

import Geolocation from "../components/Shared/Geolocation/Geolocation";
import Overlay from "../components/Overlay/Overlay";
import Loading from "../components/Overlay/Loading/Loading";
import Message from "../components/Overlay/Message/Message";
import Dialog from "../components/Overlay/Dialog/Dialog";
import Menu from "../components/Overlay/Menu/Menu";
import LabelReader from "../components/Overlay/LabelReader/LabelReader";
import Login from "../components/Overlay/Login/Login";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

type layoutProps = {};

const Layout = (props: PropsWithChildren<layoutProps>) => {
    const appCtx = useContext(AppContext);
    const authCtx = useContext(AuthContext);
    //const appData:any = useLoaderData();

    const bind = useDrag((state) => {
        //console.log(state);
        //if(state.swipe[0] === 1) appCtx.toggleMenu(true);
    }, {});

    const { sendRequest: loadBoxTypes } = useAPI((data: any) => {
        console.log({data});
    });

    useEffect(() => {
        /* loadBoxTypes({
            endpoint: 'boxes',
            loading: 'Loading data...',
            auth: true,
            doNotMock: true
        }); */
        //appCtx.initGeolocation();
    }, [appCtx, loadBoxTypes]);
    
    return (
        <>
            <Geolocation />
            <Overlay>
                <Loading />
                <Message />
                <Dialog />
                <Menu />
                <LabelReader />
                <Login />
            </Overlay>
            <Header />
            <main className={!authCtx.user || !appCtx.init ? 'hide' : ''} {...bind()}>
                <div>
                    <Outlet />
                    {props.children}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Layout;

type data = {
    request: any,
    params: any
};

export async function appLoader (data: data) {
    //console.log('app-level data', data);
    return data;
};