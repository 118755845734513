import { useCallback, useContext, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import useReader from '../../hooks/use-reader';

import AppContext from '../../store/app-context';

import BoxList from '../../components/Shared/BoxList/BoxList';

//import Address from '../../components/Shared/Address/Address';
//import Signature from "../../components/Shared/Signature/Signature";

import './CourierWarehouse.scss';

const CourierWarehouse = () => {
    const appCtx:any = useContext(AppContext);

    const navigate = useNavigate();
    const params:any = useParams();

    const { startQuagga } = useReader(null);
    
    const updateBox = useCallback((box:any) => {
        let warehouse = JSON.parse(JSON.stringify(appCtx.warehouse));

        warehouse[params.warehouse].boxes.forEach((boxInList:any) => {
            if(boxInList.barcode === box.barcode){
                boxInList = box;
            }
        });
        
        appCtx.updateWarehouse(warehouse);
    }, [appCtx, params.warehouse]);

    useEffect(() => {
        if(!appCtx.warehouse.load.boxes || !appCtx.warehouse.unload.boxes){
            navigate('/courier');
        }else{
            let footerConfig = {
                heading: '',
                pathname: '',
                buttons: [
                    {
                        label: 'Scan',
                        action: () => {
                            startQuagga({
                                boxes: appCtx.warehouse[params.warehouse].boxes,
                                update: updateBox
                            });
                        }
                    }
                ]
            };

            appCtx.updateFooter(footerConfig);
        }
    }, [appCtx, navigate, startQuagga, updateBox, params.warehouse]);

    return(
        <>
            {appCtx.warehouse[params.warehouse].boxes && 
                <>
                    <h1>
                        Warehouse {params.warehouse} ({appCtx.warehouse[params.warehouse].progress} of {appCtx.warehouse[params.warehouse].boxes.length})
                    </h1>
                    {params.warehouse === 'load' && <BoxList boxes={appCtx.warehouse.load.boxes} progress={appCtx.warehouse.load.progress} update={updateBox} />}
                    {params.warehouse === 'unload' && <BoxList boxes={appCtx.warehouse.unload.boxes} progress={appCtx.warehouse.unload.progress} update={updateBox} />}
                    {/* <Address data={appCtx.warehouse.address} />
                    {appCtx.warehouse.stopType === 'collection' && <p>Loading scanning</p>}
                    {appCtx.warehouse.stopType === 'delivery' && <p>Unloading</p>}
                    <Signature /> */}
                </>
            }
        </>
    );
};

export default CourierWarehouse;