import { useContext } from 'react';

import useReader from '../../../hooks/use-reader';

import ReaderContext from '../../../store/reader-context';

import BoxList from '../../Shared/BoxList/BoxList';

import './LabelReader.scss';

function LabelReader() {
    const readerCtx = useContext(ReaderContext);
    const { stopQuagga } = useReader(null);

    const boxesScanned = () => {
        let progress:number = 0;
        readerCtx.config.boxes.forEach((box:any) => {
            if(box.scanned) progress++;
        });
        return progress;
    };

    return (
        <div id="reader" className={readerCtx.started ? 'shown' : ''}>
            <div className="inner">
                <div id="quagga"></div>
                <div className="feedback">
                    {boxesScanned() === 0 && <div className="title">
                        <h3>Nothing scanned yet</h3>
                        <p>Please point your camera at a barcode.</p>
                    </div>}
                    {readerCtx.messages.map((message:any, index:number) => {
                        return (
                            <p key={index} className={'message ' + message.type}>{message.text}</p>
                        );
                    })}
                    {readerCtx.config.boxes.length > 0 && <BoxList boxes={readerCtx.config.boxes} progress="0" update={readerCtx.config.update} />}
                </div>
                <button className="danger" onClick={stopQuagga}>Close</button>
            </div>
        </div>
    );
}

export default LabelReader;