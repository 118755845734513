import { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useReader from '../../hooks/use-reader';

import AppContext from '../../store/app-context';

import Accordion from "../../components/Shared/Accordion/Accordion";
import BoxList from '../../components/Shared/BoxList/BoxList';
    
import './DispatchPick.scss';
import { FaCheck } from 'react-icons/fa';
    
const DispatchPick = () => {
    const appCtx = useContext(AppContext);

    const navigate = useNavigate();

    const [shownCourier, setShownCourier] = useState('');

    const { startQuagga } = useReader(null);

    const toggleCourier = (event:any) => {
        const showCourier = event.target.id;

        setShownCourier((prevShownCourier:any) => {
            return prevShownCourier === showCourier ? '' : showCourier;
        });
    };

    const updateBox = useCallback((box:any) => {
        let warehouse = JSON.parse(JSON.stringify(appCtx.warehouse));

        warehouse.pick.forEach((courier:any) => {
            courier.boxes.forEach((boxInList:any) => {
                if(boxInList.barcode === box.barcode){
                    boxInList = box;
                }
            });
        });
        
        appCtx.updateWarehouse(warehouse);
    }, [appCtx]);

    useEffect(() => {
        if(appCtx.warehouse.pick.length === 0){
            navigate('/dispatch');
        }else{
            let boxes:any = [];

            appCtx.warehouse.pick.forEach((courier:any) => {
                boxes = boxes.concat(courier.boxes);
            });

            appCtx.updateFooter({
                heading: '',
                pathname: '',
                buttons: [
                    {
                        label: 'Scan',
                        action: () => {
                            startQuagga({
                                boxes: boxes,
                                update: updateBox
                            });
                        }
                    }
                ]
            });
        }
    }, [appCtx, startQuagga, updateBox, navigate]);

    return(
        <>
            <h1>Pick for dispatch</h1>
            {appCtx.warehouse.pick.map((courier:any) => {
                let accordionConfig:any = {
                    heading: courier.vehicleId,
                    headingExtra:  courier.progress === courier.boxes.length ? <FaCheck className="ok" /> : <span>{courier.progress + ' of ' + courier.boxes.length}</span>,
                    name: courier.vehicleId,
                    content: <BoxList boxes={courier.boxes} progress="0" update={updateBox} />
                };
                return <Accordion key={courier.id} config={accordionConfig} shown={shownCourier} toggle={toggleCourier} />;
            })}
        </>
    );
};

export default DispatchPick;