import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useReader from '../../hooks/use-reader';

import AppContext from '../../store/app-context';

import BoxList from '../../components/Shared/BoxList/BoxList';

import './IncomingStore.scss';

const IncomingStore = () => {
    const appCtx = useContext(AppContext);
    
    const navigate = useNavigate();

    const { startQuagga } = useReader(null);

    const updateBox = useCallback((box:any) => {
        let location:string = appCtx.location;

        if(typeof box === 'string'){
            location = box;
            appCtx.updateLocation(location);
        }
        
        if(typeof box === 'object'){
            let warehouse = JSON.parse(JSON.stringify(appCtx.warehouse));
            warehouse.store.boxes.forEach((boxInList:any) => {
                if(boxInList.barcode === box.barcode){
                    boxInList = box;
                }
            });
            appCtx.updateWarehouse(warehouse);
        }
    }, [appCtx]);

    useEffect(() => {
        if(appCtx.warehouse.store.boxes.length === 0){
            navigate('/incoming');
        }else{
            appCtx.updateFooter({
                heading: '',
                pathname: '',
                buttons: [
                    {
                        label: 'Scan',
                        action: () => {
                            startQuagga({
                                boxes: appCtx.warehouse.store.boxes,
                                update: updateBox
                            });
                        }
                    }
                ]
            });
        }
    }, [appCtx, startQuagga, updateBox, navigate]);

    return(
        <>
            <h1>Incoming store</h1>
            <BoxList boxes={appCtx.warehouse.store.boxes} progress="0" update={updateBox} />
        </>
    );
};

export default IncomingStore;