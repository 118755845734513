import { useState, useContext, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useAPI from '../../hooks/use-api';

import AppContext from '../../store/app-context';

import './Incoming.scss';

const Incoming = () => {
    const [loaded, setLoaded] = useState(false);

    const [incomingData, setIncomingData] = useState({
        receive: [],
        store: {
            boxes: [],
            progress: 0
        }
    });

    const appCtx = useContext(AppContext);

    const navigate = useNavigate();

    const { calcProgress } = useAPI(null);

    const { sendRequest: loadIncoming } = useAPI((data: any) => {
        let dataForIncoming:any = {
            receive: [],
            store: {
                boxes: [],
                progress: 0
            }
        };

        if(appCtx.settings.environment === 'training'){
            data.couriers.forEach((courier:any, index:number) => {
                let newCourier:any = courier.courier;
                newCourier.boxes = []
                newCourier.progress = 0;

                courier.orders.forEach((order:any, index:number) => {
                    newCourier.boxes = newCourier.boxes.concat(order.boxes);
                    dataForIncoming.store.boxes = dataForIncoming.store.boxes.concat(order.boxes);
                });

                dataForIncoming.store.progress = calcProgress('warehouse-store', dataForIncoming.store);
                newCourier.progress = calcProgress('warehouse-unload', newCourier);

                dataForIncoming.receive.push(newCourier);
            });
        }

        console.log('processed data', dataForIncoming);
        
        setIncomingData(dataForIncoming);

        appCtx.updateWarehouse({
            receive: dataForIncoming.receive,
            store: dataForIncoming.store
        });

        appCtx.updateFooter({
            heading: '',
            pathname: '',
            buttons: [
                {
                    label: 'Receive',
                    action: () => {
                        navigate('/incoming/receive');
                    }
                },
                {
                    label: 'Store',
                    action: () => {
                        navigate('/incoming/store');
                    }
                }
            ]
        });

        appCtx.updateLocation('');
    });

    useEffect(() => {
        if(!loaded){
            loadIncoming({
                endpoint: 'incoming',
                method: 'GET',
                loading: 'Loading incoming boxes...'
            });

            setLoaded(true);
        }
    }, [loadIncoming, setLoaded, loaded]);

    return(
        <div id="incoming">
            <div>
                <h2>Receive</h2>
                {incomingData.receive.map((courier:any) => {
                    return (
                        <div key={courier.id} className={'courier' + (courier.progress === courier.boxes.length ? ' ok' : '')}>
                            <div className="underline">{courier.vehicleId}</div>
                            <div>
                                <span>{courier.progress} of {courier.boxes.length}</span>
                                {courier.progress === courier.boxes.length && <FaCheck />}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div>
                <h2>Store</h2>
                <div className={'store' + (incomingData.store.boxes.length > 0 && incomingData.store.progress === incomingData.store.boxes.length ? ' ok' : '')}>
                    <span>{incomingData.store.progress} of {incomingData.store.boxes.length}</span>
                    {incomingData.store.boxes.length > 0 && incomingData.store.progress === incomingData.store.boxes.length && <FaCheck />}
                </div>
            </div>
        </div>
    );
};

export default Incoming;