import { useState, useContext, useEffect, useMemo } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import AppContext from "../../store/app-context";

import './Header.scss';
import { FaAngleDoubleRight, FaArrowRight } from 'react-icons/fa';

const Header = () => {
    const appCtx = useContext(AppContext);
    const [screenTitle, setScreenTitle] = useState({
        title: '',
        parent: {
            path: '',
            name: ''
        }
    });

    const breadcrumbsConfig:any = useMemo(() => {
        return  {
            '/': { title: 'Dashboard', parent: null},
            '/courier': { title: 'Courier Route', parent: { path: '/', name: 'Dashboard'}},
            '/courier/order': { title: 'Order', parent: { path: '/courier', name: 'Courier Route'}},
            '/courier/warehouse': { title: 'Warehouse', parent: { path: '/courier', name: 'Courier Route'}},
            '/incoming': { title: 'Incoming', parent: { path: '/', name: 'Dashboard'}},
            '/incoming/receive': { title: 'Receive', parent: { path: '/incoming', name: 'Incoming'}},
            '/incoming/store': { title: 'Store', parent: { path: '/incoming', name: 'Incoming'}},
            '/dispatch': { title: 'Dispatch', parent: { path: '/', name: 'Dashboard'}},
            '/dispatch/pick': { title: 'Pick', parent: { path: '/dispatch', name: 'Dispatch'}},
            '/dispatch/load': { title: 'Load', parent: { path: '/dispatch', name: 'Dispatch'}},
            '/settings': { title: 'Settings', parent: { path: '/', name: 'Dashboard'}},
            '/tools': { title: 'Tools', parent: { path: '/', name: 'Dashboard'}}
        };
    }, []);

    const { pathname, search } = useLocation();
    const params = useParams();

   useEffect(() => {
        let path = pathname;

        if(Object.keys(params).length > 0){ // probably a better way to do this
            path = path.replace('/' + params.order, '');
            path = path.replace('/' + params.warehouse, '');
        }

        let breadcrumbs = breadcrumbsConfig[path];
        if(!breadcrumbs) breadcrumbs = { title: 'No breadcrumbs configured', parent: null };

        setScreenTitle(breadcrumbs);
    }, [search, params, pathname, breadcrumbsConfig]);

    return (
        <header>
            <div className="inner">
                <FaAngleDoubleRight className="link" onClick={appCtx.toggleMenu} />
                {appCtx.settings.environment === 'training' && <span className="training-msg">training</span>}
                <img src="/kit-keeper-logo.svg" alt="Kit Keeper logo" />
                <h1>
                    {screenTitle.parent && <>
                        <Link to={screenTitle.parent.path}>{ screenTitle.parent.name }</Link>
                        <FaArrowRight className="" />
                    </>}
                    <strong>{ screenTitle.title }</strong>
                </h1>
            </div>
        </header>
    );
}

export default Header;